<template>
  <default-layout>
    <div class="p-4">
      <input-phone-number formType="page" />
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import InputPhoneNumber from '@/components/auth/input-phone-number';
import { mapState } from 'vuex';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    InputPhoneNumber,
  },
  computed: {
    ...mapState({}),
  },
  methods: {},
  mounted() {},
};
</script>
