<template>
  <section class="modal--container auth--container">
    <div class="title--text">
      {{ $t('loginRegister.inputPhoneNumberTitle') }}
    </div>
    <div class="subtitle--text mb-4">
      {{ $t('loginRegister.inputPhoneNumberDesc') }}
    </div>
    <SectionLoader :show="isLoading" />
    <b-alert :variant="alertType" :show="showAlert">
      <div v-html="messageAlert"></div>
    </b-alert>
    <form id="loginForm" @submit.prevent="sendOtp">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('phone') }">
        <client-only>
          <vue-tel-input
            id="phone"
            @input="phoneInput"
            :validCharactersOnly="true"
            defaultCountry="id"
            :onlyCountries="['id']"
            :inputOptions="inputOptions"
          />
        </client-only>
        <span class="val-error" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>

      <hr class="separator" />
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
        {{ $t('loginRegister.sendOtp') }}
      </button>
    </form>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const SectionLoader = () => import('@/components/content-loading/section-loading');

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  props: ['formType', 'dismissable'],
  components: {
    SectionLoader,
    VueTelInput,
  },
  data() {
    return {
      phone: null,
      isLoading: false,
      showAlert: false,
      messageAlert: null,
      alertType: '',
    };
  },
  computed: {
    inputOptions() {
      return {
        placeholder: this.$t('profile.placeholder.phone'),
      };
    },
  },
  validators: {
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
  async mounted() {
    this.validation.reset();
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.number) {
        this.phone = phoneObject.number;
      } else {
        this.phone = null;
      }
    },
    async sendOtp() {
      try {
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          await this.$store.dispatch('global/updatePhoneSendOtp', {
            phone: this.phone,
          });
          this.$router.replace('/otp');
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
